import { config } from './config';

export { config };
/**
 * 页面跳转
 * @param {Object} url
 */
export function goToPage(url) {
    let nowtime = new Date().getTime();
    var ispass = true; //是否验证
    var token, expiryDate;
    for (let i = 0; i < config.undealArr.length; i++) {
        let oul = config.undealArr[i];
        // console.log(url.indexOf(oul));
        if (url.indexOf(oul) > -1) {
            ispass = false;
        }
    }
    if (ispass) { //要鉴权
        token = localStorage.wavpayToken;
        expiryDate = localStorage.expiryDate;
        if (token == undefined || token == "undefined" || (token != undefined && expiryDate < nowtime)) { //过期
            alert("Your session has expired. Please login again");
            window.location.href = "Login.html";
        } else { //通过
            window.location.href = url;
        }
    } else { //不需要鉴权
        window.location.href = url;
    }
}