import * as core from './ajaxstart';
import 'jquery-i18n-properties';
import '../bootstrap/bootstrap.bundle';
import '../materialize/materialize';
import '../i18n/jquery.i18n';
import './moduleLoad';
import '../../layer/layer';
import * as navigationService from './navigation-service';
import { config } from './config';

var api = "/api/adminauth";
var state = false;

// 账户格式验证
$("#username").blur(function() {
	if ($(this).val() == '') {
		$("#errorMsg").html($.i18n.prop("login.error4"));
		$(this).focus();
		$(this).addClass("error");
	} else {
		if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test($(this).val()) == false) {
			$("#errorMsg").html($.i18n.prop("login.error1"));
			$(this).focus();
			$(this).addClass("error");
		} else {
			$("#errorMsg").html('');
			$(this).removeClass("error");
			state = true;
		}
	}
})
//密码验证
$("#password").blur(function() {
	if ($(this).val() == '') {
		$("#errorMsg").html($.i18n.prop("login.error2"));
		$(this).focus();
		$(this).addClass("error");
	} else {
		$("#errorMsg").html('');
		$(this).removeClass("error");
		state = true;
	}
})
//回车提交
$("body").keyup(function(event) {
	if (event.keyCode == 13) { //keyCode=13是回车键
		$("#loginBtn").click();
	}
});
//提交验证
$("#loginBtn").click(function() {
	var uname = $("#username").val();
	var pwd = $("#password").val();
	if (uname == '') {
		$("#errorMsg").html($.i18n.prop("login.error4"));
		$("#username").focus();
		$("#username").addClass("error");
	} else if (pwd == '') {
		$("#errorMsg").html($.i18n.prop("login.error2"));
		$("#password").focus();
		$("#password").addClass("error");
	} else {
		var datajson = {
			username: uname,
			password: pwd
		};
		//提交数据
		var suburl = config.rooturl + api + "/adminlogin";
		$.ajax({
			type: "POST",
			url: suburl,
			data: JSON.stringify(datajson),
			dataType: 'json',
			contentType: "application/json",
			error: function(data) {
				let code = data.status;
				if (code == 400) {
					let msg = data.responseText;
					$("#errorMsg").html(msg);
				}else if (code == 401) {
					$("#errorMsg").html($.i18n.prop("login.error2"));
				} else {
					$("#errorMsg").html($.i18n.prop("server.error"));
				}
			},
			success: function(result) {
				//销毁token
				delete localStorage.wavpayToken;
				delete localStorage.expiryDate;
				//置空授权返回码
				config.authorCode = 0;
				sessionStorage.leftTree = 0;
				let token = result.token;
				let isFirst = result.isFirst;
				localStorage.wavpayToken = token;
				var expiryDate = new Date().getTime() + config.effective_time;
				localStorage.expiryDate = expiryDate;
				core.initajax();
				if (isFirst) {
					navigationService.goToPage("createNewPass.html");
				} else {
					navigationService.goToPage("ProgramPartnerList.html");
				}
			}
		});
	}

})

