class Configuration {
    rooturl = "https://cash-disbmt-admin-api-dev.wavpay.net";//服务访问地址
    effective_time = 86390000;//有效时间
    authorCode = 0;
    undealArr = ["/api/adminauth", "password-changed.html", "login.html", "forgotPassword.html",
        "Request-password.html", "changeYourPasswordEmail.html", "password-created-successfully.html", "firstLogin.html"
    ];

    get rooturl() {
        return this.rooturl;
    }

    get effective_time() {
        return this.effective_time;
    }

    get undealArr() {
        return this.undealArr;
    }

    set authorCode(val) {
        this.authorCode = val;
    }

    get authorCode() {
        return this.authorCode;
    }
}

export var config = new Configuration();